export const ru = {
  translation: {
    header: {
      home: "Главная",
      worldChampions: "Чемпионы мира",
      play: "Играть",
    },
    championsList: {
      header: "Чемпионы мира по шахматам",
      text: "Классическая линия чемпионов мира по шахматам началась с поражения Вильгельма Стейница Йоханнесу Цукерторту в матче 1886 года. С тех пор чемпионат мира обычно разыгрывается в матче между действующим чемпионом и претендентом, хотя турниры иногда использовались по разным причинам.",
      steinitz: "Вильгельм Стейниц",
      lasker: "Эмануэль Ласкер",
      capablanca: "Хосе Рауль Капабланка",
      alekhine: "Александр Алехин",
      euwe: "Макс Эйве",
      botvinnik: "Михаил Ботвинник",
      smyslov: "Василий Смыслов",
      tal: "Михаил Таль",
      petrosian: "Тигран Петросян",
      spassky: "Борис Спасский",
      fischer: "Роберт Джеймс 'Бобби' Фишер",
      karpov: "Анатолий Карпов",
      kasparov: "Гарри Каспаров",
      kramnik: "Владимир Крамник",
      anand: "Вишванатан Ананд",
      carlsen: "Магнус Карлсен",
    },
    game: {
      white: "Белые",
      black: "Черные",
      restart: "Начать сначала",
      won: "Вы выиграли!",
      lost: "Вы проиграли.",
      stalemate: "Ничья. Игра завершена.",
      check: "Шах!",
    },
  },
};

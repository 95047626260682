import React from "react";

const PageNotFound = () => {
  return (
    <div className="container flex">
      <h1>Oops! Page not found.</h1>
    </div>
  );
};

export default PageNotFound;

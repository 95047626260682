export const am = {
  translation: {
    header: {
      home: "Գլխավոր",
      worldChampions: "Աշխարհի չեմպիոններ",
      play: "Խաղալ",
    },
    championsList: {
      header: "Շախմատի աշխարհի չեմպիոններ",
      text: "Շախմատի աշխարհի չեմպիոնների դասական շարքը սկսվեց Վիլհելմ Շտայնիցի պարտությունից Յոհաննես Զուկերտորտին 1886 թվականի խաղում: Այդ ժամանակից ի վեր, Աշխարհի առաջնությունը սովորաբար վիճարկվում էր գործող չեմպիոնի և մարտահրավերի միջեւ մրցավեճում, չնայած որ մրցաշարերը տարբեր առիթներով օգտագործվել են առիթներով:",
      steinitz: "Վիլհելմ Շտայնից",
      lasker: "Էմանուել Լասկեր",
      capablanca: "Խոսե Ռաուլ Կապաբլանկա",
      alekhine: "Ալեքսանդր Ալյոխին",
      euwe: "Մաքս Եվ",
      botvinnik: "Միխայիլ Բոտվիննիկ",
      smyslov: "Վասիլի Սմիսլով",
      tal: "Միխայիլ Թալ",
      petrosian: "Տիգրան Պետրոսյան",
      spassky: "Բորիս Սպասկի",
      fischer: "Ռոբերտ 'Բոբի' Ֆիշեր",
      karpov: "Անատոլի Կարպով",
      kasparov: "Գարի Կասպարով",
      kramnik: "Վլադիմիր Կրամնիկ",
      anand: "Վիշվանաթան Անանդ",
      carlsen: "Մագնուս Կարլսեն",
    },
    game: {
      white: "Սպիտակ",
      black: "Սև",
      restart: "Սկսել նորից",
      won: "Դուք հաղթել եք!",
      lost: "Դուք պարտվել եք",
      stalemate: "Ոչ ոքի. Խաղն ավարտված է.",
      check: "Շախ!",
    },
  },
};
